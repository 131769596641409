<template>
    <div class="page-table mainDiv mb-30" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.yonetici.guncelleme.title")}}
        </div>

        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="loading || detailLoading"
        :element-loading-text='loading ? $t("src.views.apps.yonetici.guncelleme.updateLoading") : $t("src.views.apps.yonetici.guncelleme.stateLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="yoneticiForm" :rules="rulesYonetici" ref="yoneticiForm" class="demo-ruleForm">
                <div class="card-base card-shadow--medium animated fadeInUp">
                    <el-row type="flex" justify="center">
                        <el-col :lg="4" :md="4" :sm="20" :xs="20" class="col-p" style="display: flex !important; justify-content: center !important; padding-top: 15px !important">
                            <el-form-item prop="image">
                                <el-upload
                                    action=""
                                    :auto-upload="false"
                                    :limit="1"
                                    id="yoneticiguncelle-resim"
                                    :file-list="imageListMain"
                                    :on-change="imageUpload"
                                    :on-remove="imageRemove"
                                    accept=".jpg,.jpeg,.png"
                                    ref="upload"
                                    list-type="picture-card">
                                    <i slot="default" class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center"> 
                        <el-col :lg="10" :md="10" :sm="24" :xs="24" class="col-p">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item>
                                    <span>{{$t("src.views.apps.yonetici.tanimlama.tcOrEposta")}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8" :md="8" :sm="8" :xs="24" class="col-p">
                                <el-form-item>
                                    <el-switch
                                        v-model="yoneticiForm.switch"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="red"
                                        inactive-color="#13ce66"
                                        :active-text='$t("src.views.apps.yonetici.tanimlama.switchActiveText")'
                                        :inactive-text='$t("src.views.apps.yonetici.tanimlama.switchInactiveText")'>
                                    </el-switch>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="16" :md="16" :sm="16" :xs="24" class="col-p">
                                <el-form-item
                                    :label='$t("src.views.apps.yonetici.tanimlama.kullaniciAdi")'
                                    prop="kullaniciAdi">: &nbsp;
                                    {{yoneticiForm.switch === '1' ? yoneticiForm.eposta : yoneticiForm.tcNo}}
                                </el-form-item>
                            </el-col>

                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.yetki")' prop="tur">:
                                        <el-select
                                            size="small"
                                            v-model="yoneticiForm.tur"
                                            style="width: 100%"> 
                                            <el-option value="1" label="Tam Yetkili">{{$t("src.views.apps.yonetici.tanimlama.tamYetki")}}</el-option>
                                            <el-option value="2" label="Sınırlı Yetkili">{{$t("src.views.apps.yonetici.tanimlama.sinirliYetki")}}</el-option>
                                            <el-option value="3" label="Listeleme Yetkisi"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.adSoyad")' prop="adSoyad">:
                                        <el-input v-model="yoneticiForm.adSoyad" :placeholder='$t("src.views.apps.messages.enterNameSurname")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.tcNo")' prop="tcNo">:
                                        <el-input
                                            size="small"
                                            v-model="yoneticiForm.tcNo" v-mask="'###########'"
                                            :placeholder='$t("src.views.apps.messages.enterTCNo")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.cepTelefon")' prop="cepTelefon">:
                                            <el-input
                                                size="small"
                                                v-model="yoneticiForm.cepTelefon"
                                                v-mask="'+90(###)### ## ##'"
                                                placeholder="+90(555)555 55 55"></el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.telefonSabit")' prop="sabitTelefon">:
                                        <el-input
                                            size="small"
                                            v-model="yoneticiForm.sabitTelefon"
                                            v-mask="'+90(###)### ## ##'"
                                            placeholder="+90(346)555 55 55"></el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.dahili")' prop="dahili">:
                                        <el-input
                                            size="small"
                                            v-model="yoneticiForm.dahili" v-mask="'######'"
                                            placeholder="555555"></el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                        :label='$t("src.views.apps.yonetici.tanimlama.email")'
                                        prop="eposta">:
                                        <el-input type="email"
                                            size="small"
                                            v-model="yoneticiForm.eposta"
                                            placeholder="example@gmail.com"></el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                        :label='$t("src.views.apps.messages.enterMailAgain")' prop="eposta2">:
                                        <el-input type="email"
                                            size="small"
                                            v-model="yoneticiForm.eposta2"
                                            placeholder="example@gmail.com"></el-input>
                                    </el-form-item>
                                </el-col>

                                
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <div class="display-end mb-10">
                                        <el-form-item>
                                            <el-popover placement="top" width="300" v-model="visibleSil">
                                                <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                                <div style="text-align: right; margin: 0">
                                                    <el-button size="mini" type="primary" @click="resetForm('yoneticiForm')">
                                                        {{$t('src.views.apps.genel.yes')}}
                                                    </el-button>
                                                    <el-button size="mini" @click="visibleSil = false;">
                                                        {{$t('src.views.apps.genel.no')}}
                                                    </el-button>
                                                </div>
                                                <el-button size="small" slot="reference">
                                                    {{$t('src.views.apps.genel.temizle')}}
                                                </el-button>
                                            </el-popover>
                                            &nbsp;
                                            <el-button v-on:click="yoneticiGuncelle('yoneticiForm')" size="small" type="primary">
                                                {{$t("src.views.apps.genel.guncelle")}}
                                            </el-button>
                                            
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Password from 'vue-password-strength-meter'
    import yoneticiService from '../../../WSProvider/YoneticiService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from "@/components/event-bus";
    import JQuery from 'jquery';

    let $ = JQuery;

    export default {
        name: "YoneticiDuzenle",
        components: {Password},
        mounted() {
            this.yonetimUpdateData = this.$store.getters.getYoneticiUpdateData;
            this.getYoneticiDetay();
        },
        computed: {
            yoneticiUpdateData(){
                return this.$store.getters.getYoneticiUpdateData;
            }
        },
        watch: {
            yoneticiUpdateData(val){
                this.yonetimUpdateData = val
                this.getYoneticiDetay();
            }
        },
        data() {
            return {
                loading: false,
                detailLoading: false,

                imageListMain: [],
                visibleSil: false,
                yonetimUpdateData: '',
                yoneticiForm: {
                    yoneticiID: "",
                    image: [],
                    tur: '2',
                    tcNo: '',
                    yetki: '2',
                    kullaniciAdi: '',
                    adSoyad: '',
                    eposta: '',
                    eposta2: '',
                    cepTelefon: '',
                    sabitTelefon: '',
                    dahili: '',
                },
                rulesYonetici: {
                    eposta: [ {required: true, message: this.$t('src.views.apps.messages.enterMail'), trigger: ["blur", "change"]},{type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: ['blur', 'change'] }],
                },
            }
        },
        methods: {
            imageRemove() {
                $('#yoneticiguncelle-resim .el-upload--picture-card').show(); // birden fazla resim eklenmesini istemiyoruz !
                this.imageListMain = [];
                this.yoneticiForm.image = [];
            },

            imageUpload(file) {
                this.imageListMain = []
                this.yoneticiForm.image = []
                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.imageListMain.push(item)

                    file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor
                    
                    this.yoneticiForm.image.push(file.raw)
                    $('#yoneticiguncelle-resim .el-upload--picture-card').hide();
                }
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                $('.el-upload-list__item-delete').click();
                this.visibleSil = false;
                this.sayfaKapat('yoneticiguncelle')
            },

            getYoneticiDetay() {
                const regex = /^\d+$/;
                this.imageRemove() // sayfa acık kaldıgında baska bir yoneticiyi guncellemek için butona tıkladıgında aynı sayfada acılıyor. O zaman resimler üst üste ekleniyor. Bu hatayı engellemek için!
                if (this.yonetimUpdateData.resim) {
                    if(this.yonetimUpdateData.resim !== ''){
                        this.imageListMain.push({
                            name: this.yonetimUpdateData.resim,
                            url: yoneticiService.imagePath + this.yonetimUpdateData.resim
                        });
                    }
                    $('#yoneticiguncelle-resim .el-upload--picture-card').hide();
                    this.yoneticiForm.image.push(this.yonetimUpdateData.resim)
                }
                this.loading = false;
                this.detailLoading = true;


                this.yoneticiForm.kullaniciAdi = this.yonetimUpdateData.yoneticiAdi
                if(this.yoneticiForm.kullaniciAdi && regex.test(this.yoneticiForm.kullaniciAdi)) {
                    this.yoneticiForm.switch = '0'
                }
                else{
                    this.yoneticiForm.switch = '1'
                }

                this.yoneticiForm.yoneticiID = this.yonetimUpdateData.ID;
                this.yoneticiForm.yetki = this.yonetimUpdateData.yetkiTuru;
                this.yoneticiForm.cepTelefon = this.yonetimUpdateData.cepTel;
                this.yoneticiForm.sabitTelefon = this.yonetimUpdateData.sabitTel;
                this.yoneticiForm.dahili = this.yonetimUpdateData.dahiliTel;
                this.yoneticiForm.adSoyad = this.yonetimUpdateData.adSoyad;                
                this.yoneticiForm.tcNo = this.yonetimUpdateData.TC;
                this.yoneticiForm.eposta = this.yonetimUpdateData.eposta;                
                this.yoneticiForm.eposta2 = this.yonetimUpdateData.eposta;
                this.yoneticiForm.tur = this.yonetimUpdateData.rol;                
                this.detailLoading = false;
            },
            
            yoneticiGuncelle(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Yöneticiyi güncellemek istediğinizden emin misiniz?").then(() => {
                        try{
                            this.loading = true;
                            yoneticiService.yoneticiGuncelle(this.yoneticiForm.yoneticiID, this.yoneticiForm.image.length > 0 ? this.yoneticiForm.image[0] : '', this.yoneticiForm.adSoyad, this.yoneticiForm.eposta, this.yoneticiForm.cepTelefon, this.yoneticiForm.sabitTelefon, this.yoneticiForm.dahili, this.yoneticiForm.tur, this.yoneticiForm.eposta).then(response => {
                                if (response.status == 200) {
                                    notification.Status('success', this, this.$t('src.views.apps.messages.adminUpdateSuccess'));
                                    this.resetForm(formName);
                                    localStorage.setItem("userDataDemirag", response.token)
                                    EventBus.$emit("yoneticiList", true)
                                    this.sayfaKapat('yoneticiguncelle')
                                }
                                this.loading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            })
                        }catch(e){
                            this.loading = false;
                        }
                    })
                }
            },


        }
    }

</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';
    .page-table {
        &.overflow {
            overflow: hidden;
        }

        .table-box {
            overflow: hidden;
        }
    }

    .el-form-item {
        font-weight: bold;
    }
</style>

<style lang="scss">
    // el-upload animasyonunu engellemek için kaldırıldı
    #yoneticiguncelle-resim{
        .el-upload-list__item {
            transition: none !important;
        }
    }
</style>